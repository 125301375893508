import { configure, defineRule} from 'vee-validate';
import AllRules from '@vee-validate/rules';
import ennames from '@/assets/names/ennames.json';
import konames  from '@/assets/names/konames.json';
import { localize } from '@vee-validate/i18n';
import en from '@/assets/locales/envalidate.json';
import ko from '@/assets/locales/kovalidate.json';

// 기본적인 룰
Object.keys(AllRules).forEach(rule => {
    defineRule(rule, AllRules[rule]);
});

//커스텀 룰 추가

/**
 * @description 패스워드 영문,숫자,특수문자 포함 8글자 이상
 */
defineRule('password_rule', value =>{
  let passwordRule = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;

  if(!passwordRule.test(value)){
    return false;
  }
  return true;
});

/**
 * @description 개인정보 동의 여부
 */
defineRule('agree_personal_information',value =>{
  if(!value){
    return false;
  }
  return true;
});

/**
 * @description : 배열 0개 이상 여부
 */
defineRule('array_count', value =>{
  if(value === 0){
    return false;
  }
  return true;
});

/**
 * @description : 파일 사이즈 체크 (5GB 이하)
 */
defineRule('file_5gb', value =>{
  if(value){
    if(5 * 1024 * 1024 * 1024 < value.size){
      return false;
    }
  }
  return true;
});


//configure
configure({
    generateMessage: localize({
      en: {
        names: ennames.names,
        messages: en.messages,
      },
      ko: {
        names: konames.names,
        messages: ko.messages,
      },
    })
  });