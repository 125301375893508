/**
 * @description yyyy-mm-dd to string
 */
const strToDate = (strdate) =>{
    if(typeof strdate === 'string'){
        const split = strdate.split('-');
        const yyyy = split[0];
        const mm = Number(split[1]);
        const date = Number(split[2]);

        const newdate = new Date(yyyy,mm-1,date);
        return newdate;
    }

    return 'not Fomat date'
}

/**
 * @description : date to string
 */
const dateToStr = (date) =>{
    
        
    if(typeof date === 'object'){
        const yyyy = date.getFullYear();
        const mm = date.getMonth()+1 > 10 ? date.getMonth()+1 : `0${date.getMonth()+1}`;
        const dd = date.getDate() > 10 ? date.getDate() : `0${date.getDate()}`;
        
        return `${yyyy}-${mm}-${dd}`;
    }

    return date;
}

export { strToDate ,dateToStr};