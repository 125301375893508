<template>
  <router-view/>
</template>

<script>
import { _axios } from '@/plugins/axios';
import { provide } from "vue";
import { strToDate, dateToStr } from '@/utils/utils';
import { useStore } from 'vuex';
export default{
  setup(){
    //store
    const store = useStore();

    //메타데이터 가져오기 vuex action
    store.dispatch('getMetaData');

    //사용자 정보 세팅 vuex action
    store.dispatch('getUserInfo');
    
    const body = document.getElementsByTagName('body');

    const modalopen = () =>{
      if(body){
        body[0].style.overflow = 'hidden'
      }
    }

    const modalclose = () =>{
      if(body){
        body[0].style.overflow = 'auto';
      }
    }   
    
    //provide를 통해 제공하고 inject를 통해 사용
    provide('axios',_axios);
    provide('modalOpen',modalopen);
    provide('modalClose',modalclose);
    provide('strToDate',strToDate);
    provide('dateToStr',dateToStr);
  }
}
</script>

<style lang="scss">

</style>