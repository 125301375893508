import { createApp } from 'vue'
import App from '@/App.vue'

//router
import router from '@/router';

//i18n
import { i18n } from '@/assets/i18n/i18n';

//vee-validate
import '@/utils/veevalidate';

import { Form, Field,ErrorMessage,FieldArray  } from 'vee-validate';


//vuex
import store from '@/store';


//font-awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faSpinner);

//date-picker
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const app =  createApp(App);
//dev tools
app.config.performance = true;
app.config.devtools = true;

//router
app.use(router);

//i18n
app.use(i18n);

//vee-validate component 등록
app.component('Form',Form);
app.component('Field',Field);
app.component('ErrorMessage',ErrorMessage);
app.component('FieldArray',FieldArray);

//vue font awesome
app.component('font-awesome-icon',FontAwesomeIcon);

//vuex
app.use(store);

//vue-date-picker
app.component('Datepicker', Datepicker);

app.mount('#app');
