// store/index.js
import { createStore } from "vuex";
import createPersistedState from 'vuex-persistedstate';
import { i18n } from '@/assets/i18n/i18n';
import { setLocale } from '@vee-validate/i18n';
import { _axios } from '@/plugins/axios';
export default createStore({
    state:{
        languageI18n : 'en',//language가 없으면 en
        accessToken:'',//accessToken
        userInfo:{},//사용자 정보
        countryCode:[],//국가 코드
        language:[],//언어코드
        nationality:[], //국가
        timezone:[],//TimeZone
        domainCode:'', // 도메인코드
    },
    getters:{},
    mutations:{
        /**
         * @description : 언어 설정
         * @param {*} state 
         * @param {*} payload 
         */
        setLanguages(state, payload){
            state.languageI18n = payload;
            //i18n 세팅
            i18n.global.locale = 'en';
            //vee-validate i18n
            setLocale('en');
        },
        /**
         * @description : accessToken 설정
         * @param {*} state 
         * @param {*} payload 
         */
        setAccessToken(state, payload){
            state.accessToken = payload;
        },
        /**
         * @description : 사용자 정보 설정
         * @param {*} state 
         * @param {*} payload 
         */
        setUserInfo(state, payload){
            state.userInfo = payload;
        },
        /**
         * @description : 메타 데이터 설정
         * @param {*} state 
         * @param {*} payload 
         */
        setMetaData(state,payload){
            state[payload.metaKey] = payload.metaData;
        },
        setDomainCode(state,payload){
            state.domainCode = payload;
        },
    },
    actions:{
        /**
         * @description : 메타데이터 설정
         * @param {*} commit(mutations 호출)
         */
        getMetaData({ commit }){
            _axios.get('/app/auth/meta')
                .then((res)=>{
                    const { success, data} = res.data;
                    if(success){
                        const { countryCode, language, nationality, timezone} = data;
                        commit('setMetaData', { metaKey:'countryCode', metaData:countryCode});
                        commit('setMetaData', { metaKey:'language', metaData:language});
                        commit('setMetaData', { metaKey:'nationality', metaData:nationality});
                        commit('setMetaData', { metaKey:'timezone', metaData:timezone});
                    }
                })
                .catch(err=>{
                    console.error('error get MetaData : ',err);
                })
        },
        /**
         * @description 사용자 정보 세팅
         * @param {*} commit(mutations 호출) 
         */
        async getUserInfo({commit, state}){
             const { accessToken,userInfo } = state;
             //토큰 존재할 경우 사용자 정보 API 태우기
             if(accessToken){
                await _axios.get('/app/user/userinfo',{
                    params:{
                        email:userInfo.email
                    }
                })
                .then((res)=>{
                    const { success, data } = res.data;
                    if(success){
                        commit('setUserInfo',data);
                    }
                })
                .catch(err=>{
                    console.error('error get UserInfo : ',err);
                })
             }
        }
    },
    plugins: [createPersistedState()],
})